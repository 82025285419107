import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";

import logo from "../../Assests/Images/logo.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FBIcon from "@mui/icons-material/Facebook";
import InstargramIcon from "@mui/icons-material/Instagram";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        backgroundColor: "#2B2B2B",
        mt: 0,
        py: 10,
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <img src={logo} width={180} />
            <Box mt={3} width="60%">
              <Typography variant="button" color="#ffffff">
                170 Upper Bukit Timah Road <br />
                #B1-42 <br />
                Bukit Timah Shopping Center Singapore 588179
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            lg={2}
            xl={2}
            sx={{ ml: { xs: -2, sm: 0 }, mt: { xs: 3, sm: 0 } }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    textAlign="center"
                    component="a"
                    href="#about-us"
                    sx={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    About Us
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    textAlign="center"
                    component="a"
                    href="#classes"
                    sx={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    Classes
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    textAlign="center"
                    component="a"
                    href="#contactUs"
                    sx={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    Contact
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            lg={2}
            xl={2}
            sx={{ ml: { xs: -2, sm: 0 }, mt: { xs: 3, sm: 0 } }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    textAlign="center"
                    component="a"
                    href="#testimonials"
                    sx={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    Testimonials
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Box
                    textAlign="center"
                    component="a"
                    href="#location"
                    sx={{
                      textDecoration: "none",
                      color: "#ffffff",
                    }}
                  >
                    Location
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            sx={{
              justifyContent: { xs: "start", md: "end" },
              mt: { xs: 3, md: 0 },
            }}
          >
            <Box width="80%">
              {/* <Box>
                <Typography
                  variant="button"
                  color="#ffffff"
                  textTransform="uppercase"
                >
                  Want to learn more about Freyt Consol?
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="button" color="#ffffff">
                  Join our online community and stay up to date with industy
                  news.
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                mt={3}
                sx={{ color: "#ffffff", height: 30 }}
              >
                <TextField
                  label="Enter your email"
                  variant="filled"
                  sx={{ color: "#ffffff", height: 40 }}
                />
                <Button
                  variant="contained"
                  color="success"
                  sx={{ height: 60, bgcolor: "#068259" }}
                >
                  Subscribe
                </Button>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            borderBottom: "1px solid white",
            my: 5,
          }}
        ></Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <LinkedInIcon
              color="white"
              fontSize="medium"
              onClick={() =>
                window.open("https://www.linkedin.com/company/mindmatics/")
              }
            />
            <FBIcon
              color="white"
              fontSize="medium"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/people/Mindmatics/100093564297316/"
                )
              }
            />
            <InstargramIcon 
                  color="white"
                  fontSize="medium"
                  onClick={() =>
                    window.open("https://www.instagram.com/mindmatics23/")
                  }
                />
          </Box>
          <Box>
            <Typography variant="caption" color="#ffffff">
              ©2023 MiNDMATiCS | Reg No: 202322601G
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
