import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Container,
  Grid,
} from "@mui/material";
import { ThemeProvider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Carousel from "react-material-ui-carousel";
import Avatar from "@mui/material/Avatar";
import theme from "../Theme/theme";
import G26 from "../Assests/Images/G26.png";
import G27 from "../Assests/Images/G27.png";
import G57 from "../Assests/Images/G57.png";
import G60 from "../Assests/Images/G60.png";

import G8 from "../Assests/Images/G8.png";
import G9 from "../Assests/Images/G9.png";
import G62 from "../Assests/Images/G62.png";
import G63 from "../Assests/Images/G63.png";
import G64 from "../Assests/Images/G64.png";
import G65 from "../Assests/Images/G65.png";
import G67 from "../Assests/Images/G67.png";
import U from "../Assests/Images/U.png";
import U1 from "../Assests/Images/U1.png";
import Uimg from "../Assests/Images/user.png";
import Q from "../Assests/Images/Q.png";
import Mask from "../Assests/Images/mask.png";

import WhatsAppButtonGreenSmall from "../Assests/Images/WhatsAppButtonGreenSmall.png";
import SchoolBoy from "../Assests/Images/school-boy.png";
import SchoolGirl from "../Assests/Images/school-girl.png";

export default function Home() {
  var items = [
    {
      name: "Veer Avlani",
      school: "ACS(INDEPENDENT)",
      course: "IBDP HL Maths - AA, 2023",
      description:
        "From the moment I joined Ms. Rehani's class, I noticed a significant shift in my understanding of Math. Her teaching methods are both engaging and effective, making complex concepts seem much simpler and more approachable. She possesses a deep knowledge of the subject matter and has an incredible ability to break down complex problems into manageable steps, ensuring that I grasp the material thoroughly",
      image: SchoolBoy,
    },
    {
      name: "Vishva Oralie",
      school: "TEMASEK JC",
      course: "JC2 H2 Maths, 2023",
      description:
        "I have been a student of Ms. Rehani since Mid Secondary 2, and it has been a transformative experience. When I first joined her class, I was struggling and failing in Maths, but within a few months, she helped me improve my grades significantly. Thanks to Ms.Rehani's guidance, I achieved Distinctions in both A Maths and E Maths in O-Levels. I am currently continuing JC2 H2 Maths under her expert tutelage.",
      image: SchoolGirl,
    },
    {
      name: "Isha Rotiwar",
      school: "NPS INTERNATIONAL SCHOOL",
      course: "Cambridge IGCSE, 2023",
      description:
        "I have been taking classes with Ms Rehani since last year .She is a very good teacher. We do many questions in class for practice and she is always able to clear any doubt I have and explain the topic very clearly and in simple terms. This has helped me become more confident for maths.",
      image: SchoolGirl,
    },
    {
      name: "Timothy",
      school: "DULWICH COLLEGE",
      course: "IBDP SL Maths - AI, 2023",
      description:
        "Ms Rehani has helped me with my Math studies for 4 months now, she has been very patient while helping me to prepare for my upcoming exams and I am extremely grateful. She helps to point out some of the areas that I was weak in and fill in the gaps in order to move on to the more complex topics. My grades improved significantly within these 4 months.",
      image: SchoolBoy,
    },
    {
      name: "Nathasha",
      school: "ST. MARGARET'S SCHOOL (SECONDARY)",
      course: "Secondary 4 - E Maths, 2023",
      description:
        "The teacher is super patient with me and goes through every chapter in detail making sure that I fully understand. She not only helps me understand the question by explaining it step by step but also gives me feedback on how I can improve.",
      image: SchoolGirl,
    },
    {
      name: "Methum",
      school: "YUAN CHING SECONDARY SCHOOL",
      course: "Secondary 4 - E Maths, 2023",
      description:
        "She is one of the first teachers I've had that doesn't teach for the sake of the class, she makes sure I understand each question before moving on and never hesitates to spend a bit more time to explain each part in detail. She also teaches in a simple manner in order for me to absorb the content.",
      image: SchoolBoy,
    },
    {
      name: "Valencia",
      school: "RIVER VALLEY HIGH SCHOOL",
      course: "JC2 H1 Maths, 2023",
      description:
        "I've been in Ms Rehani’s class for 3 months now. She is like a friend who’s super patient and chill with me, providing me the space and support to clarify my doubts, run through topics in deeper detail and reinforcing already learnt concepts.",
      image: SchoolGirl,
    },
    {
      name: "Mahiyu Bhatia",
      school: "ACS(INDEPENDENT)",
      course: "IBDP HL Maths - AA, 2023",
      description:
        "I have been a student of Rehani ma’am for the past 4 years, I had my O level exams in 2022, and required more practice to ensure that my A Math and E Math were up to standard. She was readily available whenever I needed her help. She was very supportive and made time for make-up lessons if I was unable to attend a class. She pushed me to do my best and helped me to attain distinctions in both A and E Maths. I am currently attending her IB HL Maths class and every lesson is great!",
      image: SchoolBoy,
    },
  ];
  return (
    <>
      {/* <style>
        {` .css-1abc02a{
          background-color:black !important;
          height:50px;
          width:50px;
        }

        .MuiSvgIcon-root MuiSvgIcon-fontSizeMedium .css-1jxdcj3-MuiSvgIcon-root{
          color:white;
        }
          `}
      </style> */}

      <ThemeProvider theme={theme}>
        <Box sx={{ overflow: "hidden" }}>
          <Container maxWidth="xl">
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              sx={{ py: { xs: 5, lg: 15 } }}
            >
              <Grid sm={12} md={6} lg={5} xl={5} mt={5}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: -1,
                    mt: -15,
                    ml: -12,
                  }}
                >
                  <img src={G26} alt="backone" width="90%" />
                </Box>

                <Typography variant="h1" sx={{ fontSize: 40}} color="#044B99">
                  WELCOME TO MiNDMATiCS!
                </Typography>
                <Box mt={3}>
                  <Typography variant="button" sx={{ fontSize: 24 }}>
                    We offer top-quality math tuition for students studying the
                    International Baccalaureate (IB), Cambridge Assessment
                    International Education (Cambridge International), Pearson Edexcel, and
                    Local Curriculum.
                  </Typography>
                </Box>
                <Box mt={5}>
                  <Button
                    mx={2}
                    variant="contained"
                    size="large"
                    sx={{
                      borderRadius: 2,
                      color: "white",
                      backgroundColor: "#044B99",
                      px: 5,
                    }}
                    onClick={() =>
                      window.open("https://forms.gle/WmCrRgeyoJkoCKFh6")
                    }
                  >
                    Become a Student
                  </Button>
                </Box>
              </Grid>
              <Grid
                sm={12}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="end"
                alignItems="center"
                sx={{ pt: { xs: 15, lg: 0 } }}
              >
                <img src={G57} alt="cover img" width="100%" />
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="xl" id="about-us">
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              sx={{
                pt: { xs: 5, lg: 10 },
                position: "relative",
              }}
            >
              <Grid
                sm={12}
                md={4}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop={15}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <img src={G60} alt="cover img" width="90%" />
              </Grid>
              <Grid sm={12} md={8} lg={6} xl={6} mt={6}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: -1,
                    right: -115,
                    top: -100,
                  }}
                >
                  <img src={G27} alt="backone" width="90%" />
                </Box>
                <Typography
                  variant="h2"
                  sx={{ textTransform: "uppercase" }}
                  color="#044B99"
                >
                  About Us
                </Typography>
                <Box mt={3} pr={5}>
                  <Typography variant="button">
                    We believe that mathematics is not merely a subject to be
                    studied but a powerful tool that equips individuals with
                    critical thinking, problem-solving, and analytical skills
                    that extend beyond the classroom. We are committed to
                    inspire a lifelong appreciation for mathematics, enabling
                    students to embrace its beauty and applicability in various
                    fields.
                  </Typography>
                  <Typography variant="button">
                    <br />
                    <br />
                    Our dedicated teacher, with over 10 years of teaching
                    experience in Singapore, is renowned for her exceptional
                    ability to inspire and guide students towards achieving
                    their fullest potential. Our comprehensive and personalised
                    approach ensures that each student receives the individual
                    attention they need to overcome challenges and build
                    confidence.
                  </Typography>
                  <Typography variant="button">
                    <br />
                    <br />
                    Whether you are a primary school student seeking a solid
                    foundation in mathematics, a secondary school student
                    preparing for exams, or a student pursuing advanced
                    mathematics at the pre-university level, MiNDMATiCS is here
                    to empower you with the knowledge and skills needed to
                    excel.
                  </Typography>
                </Box>
              </Grid>

              <Grid
                sm={12}
                md={4}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  display: { sm: "block", md: "none" },
                  mt: { xs: 10, sm: 10, md: 0 },
                }}
              >
                <img src={G60} alt="cover img" width="80%" />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" id="classes">
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              sx={{ py: { xs: 10, lg: 15 } }}
            >
              <Grid sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: -1,
                    mt: -15,
                    ml: -12,
                  }}
                >
                  <img src={G26} alt="backone" width="90%" />
                </Box>

                <Typography
                  variant="h2"
                  sx={{ textTransform: "uppercase" }}
                  color="#044B99"
                  textAlign="center"
                >
                  What We offer
                </Typography>
              </Grid>
              <Grid
                container
                mt={5}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 3,
                }}
              >
                <Grid item sm={12} md={5} lg={3.5} xl={3.5} mt={5}>
                  <Card
                    sx={{
                      boxShadow: "0px 0px 20px  #00000010",
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 3,
                      height: "90%",
                    }}
                  >
                    <img src={G63} alt="backone" width="80%" />

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="button"
                        component="div"
                        textAlign="center"
                        fontSize={18}
                        fontWeight={700}
                        color="#044B99"
                        mt={2}
                      >
                        Small Group Classes
                      </Typography>
                      <Typography
                        variant="button"
                        color="black"
                        textAlign="center"
                        component="div"
                      >
                        We ensure that each student receives personalized
                        attention and guidance. With fewer students per class,
                        our experienced and passionate mathematics tutors can
                        closely interact with each student.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={5} lg={3.5} xl={3.5} mt={5}>
                  <Card
                    sx={{
                      boxShadow: "0px 0px 20px  #00000010",
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 3,
                      height: "90%",
                    }}
                  >
                    <img src={G64} alt="backone" width="80%" />

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="button"
                        component="div"
                        textAlign="center"
                        fontSize={18}
                        fontWeight={700}
                        color="#044B99"
                        mt={2}
                      >
                        One-to-One Classes
                      </Typography>
                      <Typography
                        variant="button"
                        color="black"
                        textAlign="center"
                        component="div"
                      >
                        Our 1-1 classes provide an exclusive learning
                        experience, allowing students to receive undivided
                        attention and customized instruction from our dedicated
                        mathematics tutors.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={5} lg={3.5} xl={3.5} mt={5}>
                  <Card
                    sx={{
                      boxShadow: "0px 0px 20px  #00000010",
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: 3,
                      height: "90%",
                    }}
                  >
                    <img src={G65} alt="backone" width="80%" />

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="button"
                        component="div"
                        textAlign="center"
                        fontSize={18}
                        fontWeight={700}
                        color="#044B99"
                        mt={2}
                      >
                        Online Classes
                      </Typography>
                      <Typography
                        variant="button"
                        color="black"
                        textAlign="center"
                        component="div"
                      >
                        Online mathematics classes offer students the
                        opportunity to engage in a dynamic and interactive
                        learning experience from the comfort of their own homes.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Box
              bgcolor="#F0F5FA"
              py={7}
              position="relative"
              sx={{
                height: { xs: 280, sm: 220, md: 200 },
                overflow: "hidden",
                borderRadius: 5,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 0,
                  opacity: 0.1,
                  top: 0,
                  left: 0,
                }}
              >
                <img src={U} alt="backone" width="80%" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  position: "absolute",
                  zIndex: 5,
                  top: 80,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <Typography
                  variant="h2"
                  textAlign="center"
                  color="#044B99"
                  textTransform="uppercase"
                >
                  Learn. Grow. Achieve.
                </Typography>

                <Box my={3}>
                  <Typography
                    variant="button"
                    color="black"
                    textAlign="center"
                    component="div"
                  ></Typography>
                </Box>
                <Button
                  mx={2}
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: 2,
                    color: "white",
                    backgroundColor: "#044B99",
                    px: 5,
                  }}
                  onClick={() =>
                    window.open("https://forms.gle/WmCrRgeyoJkoCKFh6")
                  }
                >
                  Become a Student
                </Button>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.1,
                  bottom: 0,
                  right: 0,
                  mr: -20,
                }}
              >
                <img src={U1} alt="backone" width="80%" />
              </Box>
            </Box>
          </Container>
          <Container maxWidth="xl" sx={{ position: "relative" }} id="testimonials">
            <Grid container mt={5}>
              <Grid item sm={12} md={12} lg={8} xl={8} my={10}>
                <Typography
                  variant="h2"
                  sx={{ textTransform: "uppercase" }}
                  color="#044B99"
                >
                  Hear From our Current Students
                </Typography>
                <Carousel sx={{ mt: 5 }} interval={15000} >
                  {items.map((item, i) => (
                    <Box
                      display="flex"
                      justifyContent="start"
                      flexDirection="column"
                      alignItems="start"
                      sx={{ mb: 5, borderLeft: "5px solid #044B99", pl: 5 }}
                    >
                      <Typography
                        width="95%"
                        sx={{ fontSize: 20, alignContent: "justifyContent" }}
                      >
                        {item.description}
                      </Typography>
                      <Box display="flex" mt={3}>
                        <Avatar
                          alt="student profile"
                          src={item.image}
                          sx={{ width: 56, height: 56 }}
                        />
                        <Box display="flex" flexDirection="column" ml={3}>
                          <Typography variant="button" fontWeight={500}>
                            {item.name}, {item.school}
                          </Typography>
                          <Typography variant="button">
                            {item.course}
                          </Typography>
                        </Box>
                      </Box>
                      <Avatar
                        alt=""
                        src={Q}
                        sx={{ width: 56, height: 56, mt: 3 }}
                      />
                    </Box>
                  ))}
                </Carousel>
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                lg={4}
                xl={4}
                my={10}
                display="flex"
                justifyContent="center"
                alignItems="start"
              >
                <img src={G67} alt="cover img" width="60%" />
              </Grid>
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  right: -100,
                  top: 0,
                }}
              >
                <img src={G27} alt="backone" width="90%" />
              </Box>
            </Grid>
          </Container>

          {/* <Container maxWidth="xl" sx={{ position: "relative" }} > */}
          <Grid
            Container
            bgcolor="#F0F5FA"
            py={1}
            sx={{ width: "100%" }}
            justifyContent="space-between"
            id="location"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7219865457087!2d103.77326607582437!3d1.3432053615953985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da11c69cfa4a0f%3A0x61aabd8a9ab8fbf9!2sMiNDMATiCS!5e0!3m2!1sen!2ssg!4v1689492914829!5m2!1sen!2ssg"
              width={"100%"}
              height={400}
              style={{ border: 0 }}
              allowfullscreen={""}
              loading={"lazy"}
              referrerpolicy={"no-referrer-when-downgrade"}
            >
              Loading Map....
            </iframe>
          </Grid>
          {/* </Container> */}

          <Box
            Container
            py={15}
            sx={{
              width: "100%",
              backgroundImage: `url(${Mask})`,
              backgroundSize: "cover",
            }}
          >
            <Container maxWidth="xl" id="contactUs">
              <Box>
                <Typography
                  variant="h2"
                  sx={{ textTransform: "uppercase" }}
                  color="#044B99"
                  textAlign="center"
                >
                  Join Mindmatics and Ignite Your Mathematical Potential.
                </Typography>
              </Box>
              <Grid
                item
                size={12}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexWrap="wrap"
                mt={3}
              >
                <Box mt={5} mx={2} textAlign="center">
                  <a
                    aria-label="Chat on WhatsApp"
                    href="https://wa.me/6580163564"
                  >
                    <img
                      alt="Chat on WhatsApp"
                      src={WhatsAppButtonGreenSmall}
                    />
                  </a>
                </Box>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
