import logo from "./logo.svg";
import "./App.css";
import ResponsiveAppBar from "./Common/Navbar/navbar";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme/theme";
import AboutUs from "./Components/aboutUs";
import Testemonials from "./Components/projects";
import Gallery from "./Components/gallery";
import { Divider } from "@mui/material";
import Footer from "./Common/Footer/footer";
import Home from "./Components/home";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar />
      <Home />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
