import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    blueTwo: {
      main: "#02264D",
    },
    blueOne: {
      main: "#033873",
    },
    bluePrime: {
      main: "#044B99",
    },
    bluePrimeOne: {
      main: "#366FAD",
    },
    blueLightFive: {
      main: "#F0F5FA",
    },
    white: {
      main: "#ffffff",
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontWeight: 700,
      fontSize: 56,
      color: "#000000",
    },
    h2: {
      fontWeight: 700,
      fontSize: 34,
      color: "#000000",
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
      color: "#000000",
    },
    h4: {
      fontWeight: 700,
      fontSize: 20,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
      color: "#000000",
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      textTransform: "none",
    },
    p: {
      fontSize: 14,
      fontWeight: 500,
      color: "#000000",
    },
  },
});

export default theme;
