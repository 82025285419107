import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FBIcon from "@mui/icons-material/Facebook";
import InstargramIcon from "@mui/icons-material/Instagram";

import logo from "../../Assests/Images/logo2.png";

const pages = ["About ", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const sideNav = (
    <div>
      <React.Fragment key={"left"}>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          <Box
            sx={{
              width: "left" === "top" || "left" === "bottom" ? "auto" : 250,
              height: "100%",
            }}
            bgcolor="white"
            role="presentation"
            onClick={toggleDrawer("left", false)}
            onKeyDown={toggleDrawer("left", false)}
          >
            <Box
              variant="h6"
              noWrap
              component="a"
              href="/"
              my={3}
              sx={{ width: "100%" }}
              display="flex"
              justifyContent="center"
            >
              <img src={logo} width={140} />
            </Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <InboxIcon color="bluePrime" />
                  </ListItemIcon> */}
                  <Box
                    textAlign="center"
                    component="a"
                    href="#about-us"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    About Us
                  </Box>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <InboxIcon color="bluePrime" />
                  </ListItemIcon> */}
                  <Box
                    textAlign="center"
                    component="a"
                    href="#classes"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    Classes
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <InboxIcon color="bluePrime" />
                  </ListItemIcon> */}
                  <Box
                    textAlign="center"
                    component="a"
                    href="#testimonials"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    Testimonials
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <InboxIcon color="bluePrime" />
                  </ListItemIcon> */}
                  <Box
                    textAlign="center"
                    component="a"
                    href="#location"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    Location
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    <InboxIcon color="bluePrime" />
                  </ListItemIcon> */}
                  <Box
                    textAlign="center"
                    component="a"
                    href="#gallery"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    Contact Us
                  </Box>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
  return (
    <>
      <AppBar
        position="static"
        color="white"
        sx={{ boxShadow: "0px 0px 20px  #00000020", py: 1 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              variant="h6"
              noWrap
              component="a"
              href="/"
              mr={2}
              sx={{
                mr: 2,
                display: { xs: "none", lg: "flex" },
              }}
            >
              <img src={logo} width={240} />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer("left", true)}
                color="inherit"
              >
                {/* <MenuIcon color="bluePrime" /> */}
                <MenuIcon color="#0F2C5B" />
              </IconButton>
            </Box>

            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "none" },
                  flexGrow: 1,
                }}
              >
                <img src={logo} width={240} />
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", lg: "flex" },
                  justifyContent: "center",
                }}
              >
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    mx: 3,
                  }}
                  color="blueTwo"
                  component="a"
                  href="#about-us"
                >
                  About Us
                </Box>
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    mx: 3,
                  }}
                  color="blueTwo"
                  component="a"
                  href="#classes"
                >
                  Classes
                </Box>
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    mx: 3,
                  }}
                  color="blueTwo"
                  component="a"
                  href="#testimonials"
                >
                  Testimonials
                </Box>
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    mx: 3,
                  }}
                  color="blueTwo"
                  component="a"
                  href="#location"
                >
                  Location
                </Box>
                <Box
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    display: "block",
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 500,
                    mx: 3,
                  }}
                  color="blueTwo"
                  component="a"
                  href="#contactUs"
                >
                  Contact Us
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon
                  color="#0F2C5B"
                  fontSize="large"
                  onClick={() =>
                    window.open("https://www.linkedin.com/company/mindmatics/")
                  }
                />
                <FBIcon
                  color="#0F2C5B"
                  fontSize="large"
                  onClick={() =>
                    window.open("https://www.facebook.com/people/Mindmatics/100093564297316/")
                  }
                />
                <InstargramIcon 
                  color="#0F2C5B"
                  fontSize="large"
                  onClick={() =>
                    window.open("https://www.instagram.com/mindmatics23/")
                  }
                />
                {/* <Button
                  mx={2}
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    border: 2,
                    ml: 2,
                    px: { xs: 2, sm: 5 },
                  }}
                  color="bluePrime"
                >
                  Login
                </Button> 
                <Button
                  mx={2}
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    color: "white",
                    backgroundColor: "#0F2C5B",
                    ml: 2,
                    px: { xs: 2, sm: 5 },
                  }}
                >
                  Join Us
                </Button>
                */}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {sideNav}
    </>
  );
}
export default ResponsiveAppBar;
